import {
  Container,
  Box,
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  SpaceBetween,
  Link,
} from '@cloudscape-design/components';
import { useContext } from 'react';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';

import { BetaPost } from './beta/post';
import { BlogThumbnail } from './blog';
import { ImagePost } from './image/post';
import { IntroPost } from './intro/post';
import { PublicPost } from './public/post';
import { StylePost } from './style/post';

export function SocialMedia() {
  return (
    <Container header={<Header variant="h2">Follow Us</Header>}>
      <SpaceBetween direction="vertical" size="xs">
        <Link
          fontSize="heading-s"
          href="https://www.facebook.com/profile.php?id=100092067786917"
          target="_blank"
          external
        >
          <Box color="text-status-info" fontSize="heading-s" variant="strong">
            Facebook
          </Box>
        </Link>
        <Link
          fontSize="heading-s"
          href="https://www.instagram.com/aiescape.io/"
          target="_blank"
          external
        >
          <Box color="text-status-info" fontSize="heading-s" variant="strong">
            Instagram
          </Box>
        </Link>
        <Link fontSize="heading-s" href="https://twitter.com/AIEscapeRoom" target="_blank" external>
          <Box color="text-status-info" fontSize="heading-s" variant="strong">
            Twitter
          </Box>
        </Link>
        <Link
          fontSize="heading-s"
          href="https://www.youtube.com/@AI-Escape"
          target="_blank"
          external
        >
          <Box color="text-status-info" fontSize="heading-s" variant="strong">
            YouTube
          </Box>
        </Link>
        <Link
          fontSize="heading-s"
          href="https://www.tiktok.com/@aiescape.io"
          target="_blank"
          external
        >
          <Box color="text-status-info" fontSize="heading-s" variant="strong">
            TikTok
          </Box>
        </Link>
        <Link
          fontSize="heading-s"
          href="https://www.reddit.com/user/AI-Escape"
          target="_blank"
          external
        >
          <Box color="text-status-info" fontSize="heading-s" variant="strong">
            Reddit
          </Box>
        </Link>
        <Link
          fontSize="heading-s"
          href="https://www.linkedin.com/company/ai-escape/"
          target="_blank"
          external
        >
          <Box color="text-status-info" fontSize="heading-s" variant="strong">
            LinkedIn
          </Box>
        </Link>
      </SpaceBetween>
    </Container>
  );
}

export default function BlogHome() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  const intro = IntroPost();
  const image = ImagePost();
  const style = StylePost();
  const beta = BetaPost();
  const open = PublicPost();

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Blog', href: '' }]} />}
      className="app-layout"
      content={
        <ContentLayout
          header={
            <Header description="All your AI Escape Announcements, News, and More!" variant="h1">
              AI Escape Blog
            </Header>
          }
        >
          <Grid
            gridDefinition={[
              { colspan: { default: 12, s: 6 } },
              { colspan: { default: 12, s: 3 } },
            ]}
          >
            <SpaceBetween size="l">
              <BlogThumbnail key={open.name} {...open} />
              <BlogThumbnail key={beta.name} {...beta} />
              <BlogThumbnail key={style.name} {...style} />
              <BlogThumbnail key={image.name} {...image} />
              <BlogThumbnail key={intro.name} {...intro} />
            </SpaceBetween>
            <SocialMedia />
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
